<i18n>
{
  "en": {
    "recommended_series": "Popular Series",
    "view_more": "All Series"
  },
  "ja": {
    "recommended_series": "人気シリーズ",
    "view_more": "シリーズ一覧"
  }
}
</i18n>

<template>
<div class="container">
  <div class="section">
    <h2 class="is-primary">{{ $t('recommended_series') }}</h2>

    <div class="grid" v-if="seriesList">
      <div class="grid-item" v-for="series in seriesList" :key="series.id" @click="clickTrack('Top Page', 'Popular Series', series.name[locale])">
        <router-link
          :to="`/search/?sr=${series.id}`"
          :data-series="series.name[locale]">
          <img class="media-image" :src="`/dyn/dla/images/${series.bannersSm[locale]}`" loading="lazy" :alt="`${series.name[locale]}`" v-sfw>
        </router-link>
      </div>
    </div>

    <div class="section">
      <div class="button-box is-centered">
        <router-link to="/series/" @click="clickTrack('Top Page', 'Popular Series', 'View More')" class="button is-outlined is-large is-rounded">{{ $t('view_more') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link>
      </div>
    </div>

  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-unneeded-ternary: 0 */

import CustomLists from '@/assets/js/services/CustomLists';

export default {
  props: {
    customListId: String,
  },
  data() {
    return {
      seriesList: [],
    };
  },
  async created() {
    const customListSvc = new CustomLists();
    this.seriesList = await customListSvc.getList('series', this.customListId);

    // hide series header in the home page if there are no series set
    this.$emit('seriesEval', (this.seriesList) ? true : false);
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
